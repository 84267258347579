import React, {FC, useContext, useState} from 'react'
import DeleteConfirmModal from './DeleteConfirmModal'

interface GlobalModelContext {
  isOpen: boolean
  isLoading: boolean
  showDeleteConfirmModal: (
    deleteText: string,
    onDeletePress: () => void,
    deleteButtonText?: string
  ) => void
  hideDeleteConfirmModal: () => void
  setDeleteModalLoading: (bool: boolean) => void
}

const initialContext: GlobalModelContext = {
  isOpen: false,
  isLoading: false,
  showDeleteConfirmModal: (deleteText, onDeletePress) => {},
  hideDeleteConfirmModal: () => {},
  setDeleteModalLoading: (bool) => {},
}

const ModalContext = React.createContext(initialContext)
export const useGlobalModalContext = () => useContext(ModalContext)

type Props = {
  children?: React.ReactNode
}

const GlobalModal: FC<Props> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteText, setDeleteText] = useState('')
  const [deleteButtonText, setDeleteButtonText] = useState('')
  const [onDelete, setOnDelete] = useState({
    func: () => {},
  })

  const showDeleteConfirmModal = (
    deleteText: string,
    onDeletePress: () => void,
    deleteButtonText?: string
  ) => {
    setDeleteText(deleteText)
    setIsOpen(true)
    setOnDelete({
      func: onDeletePress,
    })
    deleteButtonText && setDeleteButtonText(deleteButtonText)
  }
  const hideDeleteConfirmModal = () => {
    setIsOpen(false)
  }
  const setDeleteModalLoading = (bool: boolean) => {
    setIsLoading(bool)
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        isLoading,
        setDeleteModalLoading,
        showDeleteConfirmModal,
        hideDeleteConfirmModal,
      }}
    >
      <DeleteConfirmModal
        isOpen={isOpen}
        hide={hideDeleteConfirmModal}
        isLoading={isLoading}
        deleteText={deleteText}
        onDeletePress={onDelete.func}
        deleteButtonText={deleteButtonText}
      />
      {children}
    </ModalContext.Provider>
  )
}

export default GlobalModal
