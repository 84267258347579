/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
// import {RootState} from '../../../setup'
import {useLayout} from '../core'

interface Props {
  notShowCompany?: boolean
}

const Footer: FC<Props> = ({notShowCompany}) => {
  const {classes} = useLayout()

  return (
    <>
      <div>
      </div>
      <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
        {/* begin::Container */}
        <div
          className={
            notShowCompany
              ? `${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-center`
              : `${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`
          }
        >
          {/* begin::Copyright */}
          {notShowCompany ? (
            ''
          ) : (
            <div className='text-dark order-2 order-md-1'>
              <span className='text-muted fw-bold me-2'>{new Date().getFullYear()}©</span>
              <span className='text-muted fw-bold '>{process.env.REACT_APP_NAME}</span>
            </div>
          )}
          {/* end::Copyright */}

          {/* begin::Nav */}
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            {/* {SSettings && SSettings.data_privacy === 1 && (
              <li
                className='menu-item'
                onClick={() => {
                  if (SSettings && SSettings.data_privacy === 1) {
                    openModal(SSettings.data_privacy_id)
                  }
                }}
              >
                <a className='menu-link ps-0 pe-2'>{SSettings.data_privacy_text}</a>
              </li>
            )}
            {SSettings && SSettings.impression === 1 && (
              <li
                className='menu-item'
                onClick={() => {
                  if (SSettings && SSettings.impression === 1) {
                    openModal(SSettings.impression_id)
                  }
                }}
              >
                <a className='menu-link pe-0 pe-2'>{SSettings.impression_text}</a>
              </li>
            )}
            {SSettings && SSettings.terms_of_use === 1 && (
              <li
                className='menu-item'
                onClick={() => {
                  if (SSettings && SSettings.terms_of_use === 1) {
                    openModal(SSettings.terms_of_use_id)
                  }
                }}
              >
                <a className='menu-link pe-0'>{SSettings.terms_of_use_text}</a>
              </li>
            )} */}
          </ul>
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Footer}
