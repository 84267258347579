import axios from "../../../../setup/axios/SetupAxios";
import { GenericResponseType } from "../../../types/GenericReponse";
import {
  CountriesListResponseType,
  LicensesListResponseType,
} from "../types/getLicensesListResponseType";

//get licenses List
export function getLicensesList() {
  return axios.get<LicensesListResponseType>("/legalTech/licensingSoftware");
}


//get licenses List
export function addLicenseSubsription(
  type: string,
  name: string,
  price: number,
  currency: string,
  price_interval: string,
  tax_behavior: string,
  licenses_quantity: number,
  locations_quantity: number
) {
  return axios.post<GenericResponseType>("/legalTech/subscriptions/add", {
    type,
    name,
    price,
    currency,
    price_interval,
    tax_behavior,
    licenses_quantity,
    locations_quantity,
  });
}

//get licenses List
export function updateLicenseSubscription(
  id: number,
  type: string,
  name: string,
  description: string,
  display_description: string,
  price: number,
  currency: string,
  price_interval: string,
  tax_behavior: string,
  tax_code: string,
  licenses_quantity: number
) {
  return axios.post<GenericResponseType>(
    `/legalTech/subscriptions/update/${id}`,
    {
      type,
      name,
      description,
      display_description,
      price,
      currency,
      price_interval,
      tax_behavior,
      tax_code,
      licenses_quantity,
    }
  );
}

export function bookSoftwareLicenseSubscription(
  subscription_id: number,
  salutation_id: number,
  title_id: number,
  firstname: string,
  lastname: string,
  email: string,
  company_uuid: string | null,
  company_name: string | null,
  company_address_1: string | null,
  company_address_2: string | null,
  company_city: string | null,
  company_zip_code: number | null,
  company_countries_id: number | null,
  recommended_domain: string | null,
  company_email: string | null,
  company_website_url: string | null,
  company_telephone: string | null
) {
  return axios.post<GenericResponseType>(`/legalTech/software/subscription`, {
    subscription_id,
    salutation_id,
    title_id,
    firstname,
    lastname,
    email,
    company_uuid,
    company_name,
    company_address_1,
    company_address_2,
    company_city,
    company_zip_code,
    company_countries_id,
    recommended_domain,
    company_email,
    company_website_url,
    company_telephone,
  });
}

//delete licenses subscription
export function deleteLicenseSubscription(id: number) {
  return axios.post<GenericResponseType>(
    `/legalTech/subscriptions/delete/${id}`
  );
}

export function fetchSoftwareSubscriptionStatus(
  type: string | null,
  id: any,
  status: string,
  order_number: any
) {
  return axios.post<GenericResponseType>(`/legalTech/booking/status`, {
    type,
    id,
    status,
    order_number,
  });
}

export function verifyLegaltechSubscription(
  partner_uuid: string,
  verification_code: string
) {
  return axios.post<GenericResponseType>(`/legalTech/software/verification`, {
    partner_uuid,
    verification_code,
  });
}

export function buyDataUserLegaltechSubscriptions(
  subscriptions_id: number[],
  partner_uuid: string,
  verification_code: string
) {
  return axios.post<GenericResponseType>(`/legalTech/content/subscription`, {
    subscriptions_id,
    partner_uuid,
    verification_code,
  });
}

export function verifyDomain(recommended_domain: string) {
  return axios.post<GenericResponseType>(`/legalTech/domain/verification`, {
    recommended_domain,
  });
}

export function verifyPartnerUUID(code: string) {
  return axios.post<GenericResponseType>(`/legalTech/partner/verification`, {
    code,
  });
}

export function getCountriesList() {
  return axios.get<CountriesListResponseType>("/client/countries");
}
