/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Switch, Route} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import LicenseMicroPage from '../modules/Licensing/components/LicenseMicroPage'
import LegalTechSoftwarePaymentSuccess from '../modules/Licensing/components/LegalTechSoftwarePaymentSuccess'
import LicenseUserDataMicroPage from '../modules/Licensing/components/LicenseUserDataMicroPage'

const Routes: FC = () => {

  return (
    <>
      <Switch>
        <Route exact path='/'>
          <LicenseMicroPage />
        </Route>
        <Route exact path='/legaltech/content/subscriptions'>
          <LicenseUserDataMicroPage />
        </Route>
        <Route path='/legaltech/software/payment/success' component={LegalTechSoftwarePaymentSuccess}></Route>
        <Route path='/*' component={ErrorsPage} />
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
