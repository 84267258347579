import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import BookSoftwareLicenseModal from "./BookSoftwareLicenseModal";

interface Props {
  item: any;
  isNotSoftware?: boolean;
  selectedDataItems?: any;
  setSelectedDataItems?: any;
  selectedUserItems?: any;
  setSelectedUserItems?: any;
  type?: string;
}

const LicenseCard: FC<Props> = ({
  item,
  isNotSoftware,
  selectedDataItems,
  setSelectedDataItems,
  selectedUserItems,
  setSelectedUserItems,
  type,
}) => {
  const intl = useIntl();
  const [
    showBookSoftwareLicenseModal,
    setShowBookSoftwareLicenseModal,
  ] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const handleSelectData = (id: number) => {
    let isExists = selectedDataItems?.filter((val: number) => val === id);

    if (isExists.length > 0) {
      let arr = selectedDataItems?.filter((val: number) => val !== id);
      setSelectedData(false);
      setSelectedDataItems(arr);
    } else {
      setSelectedData(true);
      setSelectedDataItems([...selectedDataItems, id]);
    }
  };

  const handleSelectUser = (id: number) => {
    let isExists = selectedUserItems?.filter((val: number) => val === id);

    if (isExists.length > 0) {
      let arr = selectedUserItems?.filter((val: number) => val !== id);
      setSelectedUserItems(arr);
      setSelectedUser(false);
    } else {
      setSelectedUserItems([...selectedUserItems, id]);
      setSelectedUser(true);
    }
  };

  return (
    <>
      {isNotSoftware ? (
        <div
          onClick={() =>
            type === "data"
              ? handleSelectData(item?.id)
              : handleSelectUser(item?.id)
          }
          style={{
            borderRadius: "10px",
            minWidth: "300px",
            maxWidth: "300px",
            alignSelf: "stretch",
          }}
          className={`bg-white d-flex flex-column pt-8 pb-6 px-5 license_card_hover license_card_hover_border position-relative border border-2 cursor-pointer ${
            type === "data"
              ? !selectedData
                ? "border-secondary"
                : "license_card_border"
              : !selectedUser
              ? "border-secondary"
              : "license_card_border"
          }`}
        >
          {
            <div
              style={{ top: "-8px", borderRadius: "4px" }}
              className="position-absolute bg-primary-2 text-white fs-8 px-2"
            >
              {intl.formatMessage({
                id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_IS_POPULAR",
              })}
            </div>
          }
          <i
            className={`bi bi-patch-check-fill position-absolute ${
              type === "data"
                ? selectedData
                  ? "text-primary-2"
                  : "text-gray-400"
                : selectedUser
                ? "text-primary-2"
                : "text-gray-400"
            }`}
            style={{ fontSize: "22px", top: "10px", right: "10px" }}
          ></i>
          <div className="d-flex flex-column align-items-start">
            <div className="fw-bolder" style={{ fontSize: "18px" }}>
              {item.name}
            </div>
            <div className="mt-2" style={{ color: "#4f5567" }}>
              {intl.formatMessage({
                id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MONTH_MESSAGE",
              })}
            </div>
            <div className="d-flex align-items-center mt-4">
              <div
                className="fw-bold"
                style={{
                  fontSize: "42px",
                }}
              >
                €{item.price / 100}
              </div>
              <div className="d-flex flex-column">
                <span
                  className="fs-8 ms-1 fw-bold"
                  style={{ color: "rgb(96 103 122)" }}
                >
                  <span className="text-danger">*</span>
                  {item?.price_tax_behavior}
                </span>
                <span
                  className="fs-8 ms-1 fw-bold ms-2"
                  style={{ color: "rgb(96 103 122)" }}
                >
                  per {item?.price_interval}
                </span>
              </div>
            </div>
            {item?.description && (
              <>
                <hr
                  className="w-100 opacity-50 mt-5 mb-6"
                  style={{ borderWidth: "1.5px", borderColor: "#cfcfcf" }}
                ></hr>
                <div
                  className="fw-bold fs-7 w-100 text-gray-800"
                  style={{
                    lineHeight: "22px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          {showBookSoftwareLicenseModal && (
            <BookSoftwareLicenseModal
              show={showBookSoftwareLicenseModal}
              closeModal={() => setShowBookSoftwareLicenseModal(false)}
              subscriptionId={item.id}
            />
          )}
          <div
            style={{
              borderRadius: "10px",
              minWidth: "300px",
              maxWidth: "300px",
              alignSelf: "stretch",
            }}
            className="bg-white d-flex flex-column pt-8 pb-6 px-8 license_card_hover border border-2 border-secondary position-relative"
          >
            {
              <div
                style={{ top: "-8px", borderRadius: "4px" }}
                className="position-absolute bg-primary-2 text-white fs-8 px-2"
              >
                {intl.formatMessage({
                  id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_IS_POPULAR",
                })}
              </div>
            }
            <div className="d-flex flex-column align-items-start">
              <div className="fw-bolder" style={{ fontSize: "18px" }}>
                {item.name}
              </div>
              <div className="mt-2" style={{ color: "#4f5567" }}>
                {intl.formatMessage({
                  id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_YEAR_MESSAGE",
                })}
              </div>

              <div className="mt-2 d-flex flex align-items-center" style={{ color: "#4f5567" }}>
                <span className="fw-semibold">
                  {intl.formatMessage({
                    id:
                      "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_LOCATIONS_QUANTITY_TITLE",
                  })}{' :'}
                </span>
                <span className="fw-semibold ps-1">
                  {item.licenses_quantity ? item.licenses_quantity : '-'}
                </span>
              </div>

              <div className="d-flex align-items-center mt-2">
                <div className="fw-bold" style={{ fontSize: "42px" }}>
                  €{item.price / 100}
                </div>
                <div className="d-flex flex-column">
                  <span
                    className="fs-8 ms-1 fw-bold"
                    style={{ color: "rgb(96 103 122)" }}
                  >
                    <span className="text-danger">*</span>
                    {item?.price_tax_behavior}
                  </span>
                  <span
                    className="fs-8 ms-1 fw-bold ms-2"
                    style={{ color: "rgb(96 103 122)" }}
                  >
                    per {item?.price_interval}
                  </span>
                </div>
              </div>

              <button
                className="mb-3 mt-3 btn  btn-primary-2 text-white w-100 fw-bold"
                onClick={() => setShowBookSoftwareLicenseModal(true)}
              >
                {intl.formatMessage({
                  id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_BUY",
                })}
              </button>
              {item?.description && (
                <>
                  <hr
                    className="w-100 opacity-50 mt-5 mb-6"
                    style={{ borderWidth: "1.5px", borderColor: "#cfcfcf" }}
                  ></hr>
                  <div
                    className="fw-bold fs-7 w-100 text-gray-800"
                    style={{ lineHeight: "22px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LicenseCard;
