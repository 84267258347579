import React, {FC} from 'react'
// import {Props} from 'react-apexcharts'
import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'

interface GlobalModelContext {
  isOpen: boolean
  isLoading: boolean
  deleteText: string
  deleteButtonText: string
  onDeletePress: () => void
  hide: () => void
}

const DeleteConfirmModal: FC<GlobalModelContext> = ({
  isOpen,
  isLoading,
  deleteText,
  deleteButtonText,
  onDeletePress,
  hide,
}) => {
  const intl = useIntl()
  return (
    <Modal show={isOpen} contentClassName={isLoading ? 'pe-none' : ''} centered backdrop='static'>
      {/* <Modal.Header>
        <Modal.Title>{'New post'}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className='d-flex flex-column align-items-center'>
          <i className='bi bi-exclamation-circle text-warning' style={{fontSize: '100px'}}></i>
          <p className='fs-4 mt-8 text-center'>
            {deleteText
              ? deleteText
              : intl.formatMessage({id: 'DELETE_CONFIRM_ALERT_CONFIRMATION_MESSAGE'})}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <Button variant='secondary' onClick={hide}>
          {intl.formatMessage({id: 'DELETE_CONFIRM_ALERT_NO_CANCEL'})}
        </Button>
        <Button
          variant={'danger'}
          onClick={() => {
            onDeletePress?.()
          }}
        >
          {!isLoading && (deleteButtonText ? deleteButtonText : 'Yes, delete')}
          {isLoading && (
            <span
              className='indicator-progress d-flex flex-row align-items-center'
              style={{display: 'block'}}
            >
              {intl.formatMessage({id: 'DELETE_CONFIRM_ALERT_PLEASE_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteConfirmModal
