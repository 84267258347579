import axios, { AxiosInstance } from "axios";
import config from '../../app/config'

const baseURL = 'https://bkm-api.bitkollegen.dev/api/v1';

const instance: AxiosInstance = axios.create({
  baseURL,
});

export default instance;
