export default {
  // for language :  change only names, ** donot change values or ids
  DELETE_BUTTON: {
    en: 'Delete',
    de: 'Löschen',
  },
  RESTORE_BUTTON: {
    en: 'Restore',
    de: 'Wiederherstellen',
  },
  SELECTED: {
    en: 'selected',
    de: 'ausgewählt',
  },
  NO_ITEMS_FOUND: {
    en: 'No items found.',
    de: 'Keine Elemente gefunden.',
  },
  CLOSE_BUTTON: {
    en: 'Close',
    de: 'Schließen',
  },
  SUBMIT_BUTTON: {
    en: 'Submit',
    de: 'Absenden',
  },
  EDIT_BUTTON: {
    en: 'Edit',
    de: 'Bearbeiten',
  },
  SAVE_BUTTON: {
    en: 'Save',
    de: 'Speichern',
  },
  SEND_BUTTON: {
    en: 'Send',
    de: '',
  },
  APPLY_BUTTON: {
    en: 'Apply',
    de: 'Übernehmen',
  },
  CONTINUE_BUTTON: {
    en: 'Continue',
    de: 'Weiter',
  },
  OKAY_BUTTON: {
    en: 'Okay',
    de: 'OK',
  },
  CANCEL_BUTTON: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  SIGN_IN_TITLE: {
    en: 'Sign In',
    de: 'Anmeldung',
  },
  SIGN_IN_EMAIL: {
    en: 'Email',
    de: 'E-Mail',
  },
  SIGN_IN_PASSWORD: {
    en: 'Password',
    de: 'Passwort',
  },
  SIGN_IN_LOADING_MESSAGE: {
    en: 'Please wait...',
    de: 'Bitte warten...',
  },
  RESET_BUTTON: {
    en: 'Reset',
    de: 'Zurücksetzen',
  },
  CONNECT_BUTTON: {
    en: 'Connect',
    de: 'Verbinden',
  },
  DISCONNECT_BUTTON: {
    en: 'Disconnect',
    de: 'Abmelden',
  },
  SYNC_BUTTON: {
    en: 'Sync',
    de: 'Sync',
  },
  TWEET_BUTTON: {
    en: 'Tweet',
    de: 'Tweet',
  },
  RETWEET_BUTTON: {
    en: 'Retweet',
    de: 'Retweet',
  },
  BACK_BUTTON: {
    en: 'Back',
    de: 'Zurück',
  },
  TEST_BUTTON: {
    en: 'Test',
    de: '',
  },
  TEST_BUTTON_VERIFIED: {
    en: 'Tested',
    de: '',
  },

  // legal texts
  LEGAL_TEXTS_TITLE: {
    en: 'Legal texts',
    de: 'Rechtstexte',
  },
  LEGAL_TEXTS_DELETE_MESSAGE: {
    en: 'Legal texts deleted successfully!',
    de: 'Der Rechtstext wurde erfolgreich gelöscht!',
  },
  LEGAL_TEXTS_DELETE_FAILURE_MESSAGE: {
    en: 'Legal texts delete Failed!',
    de: 'Der Rechtstext wurde nicht erfolgreich gelöscht, bitte versuchen Sie es noch mal!',
  },
  LEGAL_TEXTS_RESTORE_MESSAGE: {
    en: 'Legal texts restored successfully!',
    de: 'Der Rechtstext wurde erfolgreich wiederhergestellt!',
  },
  LEGAL_TEXTS_RESTORE_FAILURE_MESSAGE: {
    en: 'Legal texts restore Failed!',
    de: 'Der Rechtstext konnte nicht wiederhergestellt werden!',
  },
  LEGAL_TEXTS_SEARCH_PLACEHOLDER: {
    en: 'Search legal texts',
    de: 'Suche',
  },
  LEGAL_TEXTS_DELETE_CONFIRM_MESSAGE: {
    en: 'Are you sure you want to delete selected legal texts',
    de: 'Möchten Sie den Rechtstext wirklich löschen?',
  },
  LEGAL_TEXTS_RESTORE_CONFIRM_MESSAGE: {
    en: 'Are you sure you want to restore selected legal texts',
    de: 'Möchten Sie alle ausgewählten Rechstexte wiederherstellen?',
  },
  LEGAL_TEXTS_DELETE_ACCEPT_MESSAGE: {
    en: 'Yes, delete',
    de: 'Ja, löschen',
  },
  LEGAL_TEXTS_RESTORE_ACCEPT_MESSAGE: {
    en: 'Yes, restore',
    de: 'Ja, wiederherstellen',
  },
  LEGAL_TEXTS_DELETE_SELECTED: {
    en: 'Delete Selected',
    de: 'Ausgewählte löschen',
  },
  LEGAL_TEXTS_RESTORE_SELECTED: {
    en: 'Restore Selected',
    de: 'Ausgewählte wiederherstellen',
  },
  LEGAL_TEXTS_ADD_BUTTON: {
    en: ' Add Legal Text',
    de: ' Rechtstext hinzufügen',
  },
  LEGAL_TEXTS_TAB1: {
    en: 'Main',
    de: 'Aktiv',
  },
  LEGAL_TEXTS_TAB2: {
    en: 'Trash',
    de: 'Papierkorb',
  },
  LEGAL_TEXTS_ACTIVE: {
    en: 'active',
    de: 'Aktiviert',
  },
  LEGAL_TEXTS_TABLE_COLUMN1: {
    en: 'Name',
    de: 'Name',
  },
  LEGAL_TEXTS_TABLE_COLUMN2: {
    en: 'status',
    de: 'Status',
  },
  LEGAL_TEXTS_TABLE_COLUMN3: {
    en: 'Published date',
    de: 'Veröffentlichung',
  },
  LEGAL_TEXTS_TABLE_COLUMN4: {
    en: 'Created date',
    de: 'Erstellungsdatum',
  },
  LEGAL_TEXTS_STATUS_PUBLISHED: {
    en: 'Published',
    de: 'Veröffentlicht',
  },
  LEGAL_TEXTS_ELEMENT_TITLE: {
    en: 'Element',
    de: 'Element',
  },
  LEGAL_TEXTS_NO_ELEMENT: {
    en: 'No element added',
    de: 'Keien Daten vorhanden',
  },
  LEGAL_TEXTS_NO_DESCRIPTION: {
    en: 'No Description added',
    de: 'Keien Daten vorhanden',
  },
  LEGAL_TEXTS_STATUS_DRAFT: {
    en: 'Draft',
    de: 'Entwurf',
  },
  LEGAL_TEXTS_ADD_MESSAGE: {
    en: 'Legal text added successfully!',
    de: 'Der Rechtstext wurde erfolgreich erstellt!',
  },
  LEGAL_TEXTS_ADD_FAILURE_MESSAGE: {
    en: 'Cannot add legal text, please try again',
    de: 'Der Rechtstext kann nicht erstellt werden, versuchen Sie es später noch mal!',
  },
  LEGAL_TEXTS_ADD_FIELD_NAME: {
    en: 'Name',
    de: 'Name',
  },
  LEGAL_TEXTS_ADD_FIELD_TITLE: {
    en: 'Title',
    de: 'Titel',
  },
  LEGAL_TEXTS_ADD_FIELD_DESCRIPTION: {
    en: 'Description',
    de: 'Beschreibung',
  },
  LEGAL_TEXTS_ADD_FIELD_ELEMENT: {
    en: 'Element',
    de: 'Element',
  },
  LEGAL_TEXTS_ADD_SAVE_DRAFT: {
    en: 'Save draft',
    de: 'Entwurf speichern',
  },
  LEGAL_TEXTS_ADD_LOADING_MESSAGE: {
    en: 'Publishing...',
    de: 'Veröffentlichen...',
  },
  LEGAL_TEXTS_ADD_PUBLISH_TITLE: {
    en: 'Publish',
    de: '',
  },
  LEGAL_TEXTS_ADD_NAME_FIELD_REQUIRED: {
    en: 'Name is required',
    de: 'Name ist ein Pflichtfeld',
  },
  LEGAL_TEXTS_ADD_TITLE_FIELD_REQUIRED: {
    en: 'Title is required',
    de: 'Titel ist ein Pflichtfeld',
  },
  LEGAL_TEXTS_ADD_DESCRIPTION_FIELD_REQUIRED: {
    en: 'Description is required',
    de: 'Beschreibung ist ein Pflichtfeld',
  },
  LEGAL_TEXTS_ADD_ELEMENT_FIELD_REQUIRED: {
    en: 'Element is required',
    de: 'Element ist ein Pflichtfeld',
  },
  LEGAL_TEXTS_ADD_60SYMBOLS_REQUIRED: {
    en: 'Maximum 60 symbols',
    de: 'Maximal 60 Zeichen',
  },
  LEGAL_TEXTS_EDIT_SUCCESS_MESSAGE: {
    en: 'Legal edited added successfully!',
    de: 'Der Rechtstext wurde erfolgreich aktualisiert!',
  },
  LEGAL_TEXTS_EDIT_FAILURE_MESSAGE: {
    en: 'Cannot edit legal text, please try again',
    de: 'Der Rechtstext kann nicht aktualisiertwerden, bitte versuchen Sie es noch mal!',
  },
  LEGAL_TEXTS_EDIT_TITLE: {
    en: 'Legal text',
    de: 'Rechtstext',
  },
  LEGAL_TEXTS_DELETE_CONFIRMATION_MESSAGE: {
    en: 'Are you sure , you want to delete this legal text?',
    de: '',
  },
  LEGAL_TEXTS_DELETE_SUCCESS_MESSAGE: {
    en: 'Legal text deleted successfully!',
    de: 'Der Rechtstext wurde erfolgreich gelöscht!',
  },
  LEGAL_TEXTS_DELETE_FAILURE_MODAL_MESSAGE: {
    en: 'There was an error deleting this legal text!',
    de: 'Der Rechtstext konnte nicht gelöscht werden, bitte versuchen Sie es noch mal!',
  },
  LEGAL_TEXTS_MODAL_EDIT_BUTTON: {
    en: 'Edit',
    de: 'Bearbeiten',
  },
  LEGAL_TEXTS_SETTINGS_TABLE_COLUMN_NAME: {
    en: 'Name',
    de: 'Name',
  },
  LEGAL_TEXTS_SETTINGS_TABLE_COLUMN_STATUS: {
    en: 'Status',
    de: 'Status',
  },
  LEGAL_TEXTS_SETTINGS_MODAL_REQUIRED_FIELD: {
    en: 'Required',
    de: 'Pflichtfeld',
  },
  LEGAL_TEXTS_SETTINGS_MODAL_LEGALTEXT_ERROR: {
    en: 'Legal text is required',
    de: 'Rechts Text ist ein Pflichtfeld',
  },
  LEGAL_TEXTS_SETTINGS_USER_REGISTRATIONS_TITLE: {
    en: 'User registrations',
    de: 'Benutzer Registrierung',
  },
  LEGAL_TEXTS_SETTINGS_FREE_EVENTS_REGISTRATIONS_TITLE: {
    en: 'Free events registrations',
    de: 'Kostenlose Veranstaltungen',
  },
  LEGAL_TEXTS_SETTINGS_STRIPE_OFFERS_TITLE: {
    en: 'Stripe offers registrations',
    de: '',
  },


  COMMON_EDIT: {
    en: 'Edit',
    de: 'Bearbeiten',
  },
  COMMON_DELETE: {
    en: 'Delete',
    de: 'Löschen',
  },
  COMMON_SUCCESS: {
    en: 'Success',
    de: 'Erfolg',
  },
  COMMON_YES_DELETE: {
    en: 'Yes, delete',
    de: '',
  },
  COMMON_YES_RESTORE: {
    en: 'Yes, restore',
    de: '',
  },

  LEGALTECH_MASTERDATA_AUTHORITY_NAME: {
    en: 'Authorty name',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_NAME_REQUIRED: {
    en: 'Authority name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_CREATE_BTN: {
    en: 'Create new Authority',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_CREATE_MODAL_HEADER: {
    en: 'Create new Authority',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_EDIT_MODAL_HEADER: {
    en: 'Edit Authority',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_ADD_SUCCESS: {
    en: 'Authority added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_ADD_FAILURE: {
    en: 'Cannot add authority, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_EDIT_SUCCESS: {
    en: 'Authority edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_EDIT_FAILURE: {
    en: 'Cannot edit authority, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_DELETE_SUCCESS: {
    en: 'Authority deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_DELETE_FAILURE: {
    en: 'Cannot delete authority, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_MASS_DELETE_SUCCESS: {
    en: 'Authorities deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_AUTHORITY_MASS_DELETE_FAILURE: {
    en: 'Cannot delete authorities, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_NAME: {
    en: 'Specification name',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_NAME_REQUIRED: {
    en: 'Specification name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_CREATE_BTN: {
    en: 'Create new Specification',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_CREATE_MODAL_HEADER: {
    en: 'Create new Specification',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_EDIT_MODAL_HEADER: {
    en: 'Edit Specification',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_ADD_SUCCESS: {
    en: 'Specification added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_ADD_FAILURE: {
    en: 'Cannot add Specification, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_EDIT_SUCCESS: {
    en: 'Specification edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_EDIT_FAILURE: {
    en: 'Cannot edit specification, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_DELETE_SUCCESS: {
    en: 'Specification deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_DELETE_FAILURE: {
    en: 'Cannot delete Specification, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_MASS_DELETE_SUCCESS: {
    en: 'Specifications deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SPECIFICATION_MASS_DELETE_FAILURE: {
    en: 'Cannot delete specifications, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_NAME: {
    en: 'Level name',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_NAME_REQUIRED: {
    en: 'Level name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_CREATE_BTN: {
    en: 'Create new Level',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_CREATE_MODAL_HEADER: {
    en: 'Create new Level',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_EDIT_MODAL_HEADER: {
    en: 'Edit Level',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_ADD_SUCCESS: {
    en: 'Level added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_ADD_FAILURE: {
    en: 'Cannot add level, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_EDIT_SUCCESS: {
    en: 'Level edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_EDIT_FAILURE: {
    en: 'Cannot edit level, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_DELETE_SUCCESS: {
    en: 'Level deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_DELETE_FAILURE: {
    en: 'Cannot delete level, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_MASS_DELETE_SUCCESS: {
    en: 'Levels deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEVEL_MASS_DELETE_FAILURE: {
    en: 'Cannot delete levels, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_NAME: {
    en: 'Register name',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_NAME_REQUIRED: {
    en: 'Register name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_CREATE_BTN: {
    en: 'Create new Register',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_CREATE_MODAL_HEADER: {
    en: 'Create new Register',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_EDIT_MODAL_HEADER: {
    en: 'Edit Register',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_ADD_SUCCESS: {
    en: 'Register added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_ADD_FAILURE: {
    en: 'Cannot add register, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_EDIT_SUCCESS: {
    en: 'Register edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_EDIT_FAILURE: {
    en: 'Cannot edit register, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_DELETE_SUCCESS: {
    en: 'Register deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_DELETE_FAILURE: {
    en: 'Cannot delete register, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_MASS_DELETE_SUCCESS: {
    en: 'Registers deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_REGISTER_MASS_DELETE_FAILURE: {
    en: 'Cannot delete registers, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_NAME: {
    en: 'Legal nature name',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_NAME_REQUIRED: {
    en: 'Legal nature name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_CREATE_BTN: {
    en: 'Create new Legal nature',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_CREATE_MODAL_HEADER: {
    en: 'Create new Legal nature',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_EDIT_MODAL_HEADER: {
    en: 'Edit Legal nature',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_ADD_SUCCESS: {
    en: 'Legal nature added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_ADD_FAILURE: {
    en: 'Cannot add legal nature, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_EDIT_SUCCESS: {
    en: 'Legal nature edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_EDIT_FAILURE: {
    en: 'Cannot edit legal nature, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_DELETE_SUCCESS: {
    en: 'Legal nature deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_DELETE_FAILURE: {
    en: 'Cannot delete legal nature, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_MASS_DELETE_SUCCESS: {
    en: 'Legal natures deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALNATURE_MASS_DELETE_FAILURE: {
    en: 'Cannot delete legal natures, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_SORT_BY_COUNTRY: {
    en: 'Country',
    de: '',
  },

  LEGALTECH_MASTERDATA_LEGALFIELD_NAME: {
    en: 'Legal field name',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_NAME_REQUIRED: {
    en: 'Legal field name is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_COUNTRY: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_COUNTRY_REQUIRED: {
    en: 'Country is required',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_CREATE_BTN: {
    en: 'Create new legal field',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_CREATE_MODAL_HEADER: {
    en: 'Create new legal field',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_EDIT_MODAL_HEADER: {
    en: 'Edit legal field',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_ADD_SUCCESS: {
    en: 'Legal field added successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_ADD_FAILURE: {
    en: 'Cannot add legal field, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_EDIT_SUCCESS: {
    en: 'Legal field edited successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_EDIT_FAILURE: {
    en: 'Cannot edit legal field, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_DELETE_SUCCESS: {
    en: 'Legal field deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_DELETE_FAILURE: {
    en: 'Cannot delete legal field, Please try again!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_MASS_DELETE_SUCCESS: {
    en: 'legal field deleted successfully!',
    de: '',
  },
  LEGALTECH_MASTERDATA_LEGALFIELD_MASS_DELETE_FAILURE: {
    en: 'Cannot delete legal fields, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_1: {
    en: 'Code',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_2: {
    en: 'Registers',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_3: {
    en: 'LegalFields',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_4: {
    en: 'Authorities',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_5: {
    en: 'Specifications',
    de: '',
  },
  LEGALTECH_REGULATIONS_TABLE_COLUMN_6: {
    en: 'Levels',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_BTN: {
    en: 'Add new regulation',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_MODAL_HEADER: {
    en: 'Add new regulation',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_MODAL_HEADER_DESC: {
    en: 'Please enter the below details to create a regulation',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_COUNTRY_FIELD: {
    en: 'Country',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_REGULATION_SUCCESS: {
    en: 'Regulation added successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_ADD_NEW_REGULATION_FAILURE: {
    en: 'There was an issue adding regulation, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_MASS_DELETE_CONFIRM_MSG: {
    en: 'Are you sure you want to delete selected regulations?',
    de: '',
  },
  LEGALTECH_REGULATIONS_MASS_RESTORE_CONFIRM_MSG: {
    en: 'Are you sure you want to restore selected regulations?',
    de: '',
  },
  LEGALTECH_REGULATIONS_DELETE_CONFIRM_MSG: {
    en: 'Are you sure you want to delete this regulation?',
    de: '',
  },
  LEGALTECH_REGULATIONS_RESTORE_CONFIRM_MSG: {
    en: 'Are you sure you want to restore this regulation?',
    de: '',
  },
  LEGALTECH_REGULATIONS_DELETE_SUCCESS_MSG: {
    en: 'Regulation deleted successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_RESTORE_SUCCESS_MSG: {
    en: 'Regulation restored successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_DELETE_FAILURE_MSG: {
    en: 'There was an issue deleting regulation, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_RESTORE_FAILURE_MSG: {
    en: 'There was an issue restoring regulation, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_REGISTER_SUCCESS_MESSAGE: {
    en: 'Register added successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_REGISTER_ERROR_MESSAGE: {
    en: 'There was an issue adding register, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_REGISTER_DROPDOWN_TITLE: {
    en: 'Register',
    de: '',
  },
  LEGALTECH_REGULATIONS_REGISTER_SHOW_UPDATE_TITLE: {
    en: 'Do you want to show update?',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_EDIT_SUCCESS_MESSAGE: {
    en: 'Regulation update detail edited successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_EDIT_ERROR_MESSAGE: {
    en: 'There was an issue editing regulation update detail, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_ADD_SUCCESS_MESSAGE: {
    en: 'Regulation update detail added successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_ADD_ERROR_MESSAGE: {
    en: 'There was an issue adding regulation update detail, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_TITLE: {
    en: 'Add regulation update detail',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_DATE_TITLE: {
    en: 'Date',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_TOPIC_TITLE: {
    en: 'Topic',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_RELEVANCE_TITLE: {
    en: 'Relevance',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_LEGAL_BASE_TITLE: {
    en: 'Legal base',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_MISCELLANEOUS_TITLE: {
    en: 'Miscellaneous',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_DESCRIPTION_VALIDATION: {
    en: 'Description is required',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_EDIT_SUCCESS_MESSAGE: {
    en: 'Regulation update edited successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_EDIT_ERROR_MESSAGE: {
    en: 'There was an issue editing regulation update, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_ADD_SUCCESS_MESSAGE: {
    en: 'Regulation update added successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_ADD_ERROR_MESSAGE: {
    en: 'There was an issue adding regulation update, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_EDIT_TITLE: {
    en: 'Edit regulation update',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_MODAL_ADD_TITLE: {
    en: 'Add regulation update',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_DATE_TITLE: {
    en: 'Date',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_DESCRIPTION_TITLE: {
    en: 'Description',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_UPDATE_CONTENT_TITLE: {
    en: 'Update content',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_DOCUMENT_TITLE: {
    en: 'Document',
    de: '',
  },
  LEGALTECH_REGULATIONS_UPDATE_DETAIL_LINK_TITLE: {
    en: 'Link',
    de: '',
  },
  LEGALTECH_REGULATIONS_CONTENT_VALIDATION: {
    en: 'Content is required',
    de: '',
  },
  LEGALTECH_REGULATIONS_CONTENT_SUCCESS_MESSAGE: {
    en: 'Regulation content updated successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_CONTENT_ERROR_MESSAGE: {
    en: 'There was an issue updating content, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_CONTENT_TITLE: {
    en: 'Regulation content',
    de: '',
  },
  LEGALTECH_REGULATION_DELETE_SUCCESS_MESSAGE: {
    en: 'Regulation deleted successfully',
    de: '',
  },
  LEGALTECH_REGULATION_DELETE_ERROR_MESSAGE: {
    en: 'Regulation deletion unsuccessful',
    de: '',
  },
  LEGALTECH_REGULATION_DETAIL_DELETE_SUCCESS_MESSAGE: {
    en: 'Regulation detail deleted successfully',
    de: '',
  },
  LEGALTECH_REGULATION_DETAIL_DELETE_ERROR_MESSAGE: {
    en: 'Regulation detail deletion unsuccessful',
    de: '',
  },
  LEGALTECH_REGULATION_TITLE: {
    en: 'Regulation',
    de: '',
  },
  LEGALTECH_REGULATION_ADD_REGULATION_TITLE: {
    en: 'Add regulation',
    de: '',
  },
  LEGALTECH_REGULATION_SHORT_DESCRIPTION_TITLE: {
    en: 'Short description',
    de: '',
  },
  LEGALTECH_REGULATION_FILE_TITLE: {
    en: 'File',
    de: '',
  },
  LEGALTECH_REGULATION_CONTENT_TITLE: {
    en: 'Content',
    de: '',
  },
  LEGALTECH_REGULATION_NO_DETAILS_FOUND_TITLE: {
    en: 'No details found',
    de: '',
  },
  LEGALTECH_REGULATION_ADD_UPDATE_DETAIL_TITLE: {
    en: 'No details found',
    de: '',
  },
  LEGALTECH_REGULATION_TOPIC_TITLE: {
    en: 'Topic',
    de: '',
  },
  LEGALTECH_REGULATION_RELEVANCE_TITLE: {
    en: 'Relevance',
    de: '',
  },
  LEGALTECH_REGULATION_LEGAL_BASE_TITLE: {
    en: 'Legal base',
    de: '',
  },
  LEGALTECH_REGULATION_MISCELLANEOUS_TITLE: {
    en: 'Miscellaneous',
    de: '',
  },
  LEGALTECH_REGULATION_MASTERDATA_TITLE: {
    en: 'Masterdata',
    de: '',
  },
  LEGALTECH_REGULATION_AUTHORITY_TITLE: {
    en: 'Authority',
    de: '',
  },
  LEGALTECH_REGULATION_SPECIFICATION_TITLE: {
    en: 'Specification',
    de: '',
  },
  LEGALTECH_REGULATION_LEGAL_NATURE_TITLE: {
    en: 'Legal nature',
    de: '',
  },
  LEGALTECH_REGULATION_LEVEL_TITLE: {
    en: 'Level',
    de: '',
  },
  LEGALTECH_REGULATION_LEGAL_FIELDS_TITLE: {
    en: 'Legal fields',
    de: '',
  },
  LEGALTECH_REGULATION_REGISTERS_TITLE: {
    en: 'Registers',
    de: '',
  },
  LEGALTECH_REGULATION_REGISTER_REMOVE_CONFIRMATION_MESSAGE: {
    en: 'Are you sure you want to remove this register?',
    de: '',
  },
  LEGALTECH_REGULATION_REGISTER_REMOVE_SUCCESS_MESSAGE: {
    en: 'Register removed successfully!',
    de: '',
  },
  LEGALTECH_REGULATION_REGISTER_REMOVE_ERROR_MESSAGE: {
    en: 'There was an issue removing register, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATION_DELETE_CONFIRMATION_MESSAGE: {
    en: 'Are you sure you want to remove this update?',
    de: '',
  },
  LEGALTECH_REGULATION_SEARCH_PRIORITY_SUCCESS_MESSAGE: {
    en: 'Regulation Search priority updated successfully!',
    de: '',
  },
  LEGALTECH_REGULATION_SEARCH_PRIORITY_ERROR_MESSAGE: {
    en: 'There was an issue updating Search priority, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATION_SEARCH_PRIORITY_TITLE: {
    en: 'Search priority',
    de: '',
  },
  LEGALTECH_REGULATIONS_DELETE_SUCCESS_MESSAGE: {
    en: 'Regulations delete successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_DELETE_ERROR_MESSAGE: {
    en: 'There was an issue deleting regulations, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATIONS_RESTORE_SUCCESS_MESSAGE: {
    en: 'Regulations restored successfully!',
    de: '',
  },
  LEGALTECH_REGULATIONS_RESTORE_ERROR_MESSAGE: {
    en: 'There was an issue restoring regulations, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATION_TOGGLE_STATUS_ENABLED_SUCCESS: {
    en: 'Regulation is enabled successfully!',
    de: '',
  },
  LEGALTECH_REGULATION_TOGGLE_STATUS_DISABLED_SUCCESS: {
    en: 'Regulation is disabled successfully!',
    de: '',
  },
  LEGALTECH_REGULATION_TOGGLE_STATUS_ERROR_MESSAGE: {
    en: 'There was an issue updating status, Please try again!',
    de: '',
  },
  LEGALTECH_REGULATION_TOGGLE_STATUS_TITLE: {
    en: 'Status',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_TITLE_1: {
    en: "We've got a plan",
    de: "",
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_TITLE_2: {
    en: "that's perfect for you",
    de: "",
  },
  LEGALTECH_DATA_SUBSCRIPTIONS_MICROPAGE_TITLE: {
    en: 'Licensing Data Subscriptions',
    de: '',
  },
  LEGALTECH_USER_SUBSCRIPTIONS_MICROPAGE_TITLE: {
    en: 'Licensing User Subscriptions',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_BUY: {
    en: 'Get Subscription',
    de: '',
  },
  LEGALTECH_DATA_USER_SUBSCRIPTIONS_MICROPAGE_BUY: {
    en: 'Get Subscriptions',
    de: '',
  },
  LEGALTECH_DATA_USER_SUBSCRIPTIONS_SELECTED: {
    en: 'Selected',
    de: '',
  },
  LEGALTECH_DATA_USER_SELECT_SUBSCRIPTIONS_MESSAGE: {
    en: 'Please select the below subscriptions. ',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_SUCCESS: {
    en: 'Payment Success!',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_FAILED: {
    en: 'Payment Failed. Please try again later!',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_SUCCESS_BACK: {
    en: 'Go Back',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_BUY_MODAL_TITLE: {
    en: 'Add Details',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_PARTNER_UUID_REQUIRED: {
    en: 'Partner UUID is required',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_VERIFICATION_CODE_REQUIRED: {
    en: 'Verification code is required',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_TITLE: {
    en: 'Verify Subscription',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_PARTNER_UUID: {
    en: 'Partner UUID',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_VERIFICATION_CODE: {
    en: 'Verification Code',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_SUCCESS_MESSAGE: {
    en: 'Verification Successfull.',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_FAILURE_MESSAGE: {
    en: 'Verification failed. Please try again!',
    de: '',
  },
  LEGALTECH_VERIFY_SUBSCRIPTION_VERIFY_BTN: {
    en: 'Verify',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_ALREADY_HAVE_SUBSCRIPTION: {
    en: 'Already have a subscription ?',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_ADD_DETAILS_FAILURE: {
    en: 'Adding details failed. Please try again!',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS__EMPTY: {
    en: 'No Software Subscriptions',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_IS_POPULAR: {
    en: 'Popular',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_YEAR_MESSAGE: {
    en: 'Enjoy features for upto 1 year.',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MONTH_MESSAGE: {
    en: 'Enjoy features for upto 1 month.',
    de: '',
  },
  LEGALTECH_SOFTWARE_SUBSCRIPTIONS_LOCATIONS_QUANTITY_TITLE: {
    en: 'Locations',
    de: '',
  },

  // licensing
  ROUTES_LICENSING_OVERVIEW: {
    en: 'Licensing',
    de: '',
  },
  LICENSING_SUBSCRIPTIONS_TABLE_COLUMN_NAME: {
    en: 'Name',
    de: '',
  },
  LICENSING_SUBSCRIPTIONS_TABLE_COLUMN_PRICE: {
    en: 'Price',
    de: '',
  },
  LICENSING_SUBSCRIPTIONS_TABLE_COLUMN_TAXCODE: {
    en: 'Tax code',
    de: '',
  },
  LICENSING_SUBSCRIPTIONS_TABLE_COLUMN_PRODUCTID: {
    en: 'Product id',
    de: '',
  },
  LICENSING_SOFTWARE_SUBSCRIPTIONS: {
    en: 'Software subscriptions',
    de: '',
  },
  LICENSING_DATA_SUBSCRIPTIONS: {
    en: 'Data subscriptions',
    de: '',
  },
  LICENSING_USER_SUBSCRIPTIONS: {
    en: 'User subscriptions',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_MODAL_TITLE: {
    en: 'Add subscription',
    de: '',
  },
  LICENSING_ADD_SOFTWARE_SUBSCRIPTION_BTN: {
    en: 'Add software subscription',
    de: '',
  },
  LICENSING_ADD_DATA_SUBSCRIPTION_BTN: {
    en: 'Add data subscription',
    de: '',
  },
  LICENSING_ADD_USER_SUBSCRIPTION_BTN: {
    en: 'Add user subscription',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_NAME: {
    en: 'Name',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_PRICE: {
    en: 'Price',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_PRICE_CURRENCY: {
    en: 'Currency',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_PRICE_INTERVAL: {
    en: 'Interval',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_TAX_BEHAVIOUR: {
    en: 'Tax behaviour',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_LICENSE_QUANTITY: {
    en: 'Quantity',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_SUCCESS: {
    en: 'Subscription added successfully!',
    de: '',
  },
  LICENSING_EDIT_SUBSCRIPTION_SUCCESS: {
    en: 'Subscription edited successfully!',
    de: '',
  },
  LICENSING_EDIT_SUBSCRIPTION_FAILURE: {
    en: 'Subscription edit failed. Please try again!',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FAILURE: {
    en: 'Subscription adding failed. Please try again!',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_NAME_REQUIRED: {
    en: 'Name is required',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_PRICE_REQUIRED: {
    en: 'Price is required',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_PRICE_INVALID: {
    en: 'Price is invalid',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_LICENSE_QUANTITY_REQUIRED: {
    en: 'Quantity is required',
    de: '',
  },
  LICENSING_ADD_SUBSCRIPTION_FIELD_LICENSE_QUANTITY_INVALID: {
    en: 'Quantity is invalid',
    de: '',
  },
  LICENSING_DELETE_SUBSCRIPTION_CONFIRM_MESSAGE: {
    en: 'Are you sure, you want to delete this subscription?',
    de: '',
  },
  LICENSING_DELETE_SUBSCRIPTION_SUCCESS_MESSAGE: {
    en: 'Subscription deleted successfully!',
    de: '',
  },
  LICENSING_DELETE_SUBSCRIPTION_FAILURE_MESSAGE: {
    en: 'Subscription deletion failed. Please try again!',
    de: '',
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_VERIFY_ERROR_MSG: {
    en: "Error verifying UUID. Please enter a valid UUID or try again!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFY_BUTTON: {
    en: "Verify",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFYING_BUTTON: {
    en: "Verifying",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFIED_BUTTON: {
    en: "Verified",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_1_LABEL: {
    en: "Address 1",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_2_LABEL: {
    en: "Address 2",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_CITY_LABEL: {
    en: "City",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_ZIP_CODE_LABEL: {
    en: "Zip Code",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_COUNTRY_LABEL: {
    en: "Country",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_OR_NAME_REQUIRED: {
    en: "Company UUID or Comapany Name is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_1_REQUIRED: {
    en: "Address 1 is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_CITY_REQUIRED: {
    en: "City is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_ZIP_CODE_REQUIRED: {
    en: "Zip Code is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_COUNTRY_REQUIRED: {
    en: "Country is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_DOMAIN_REQUIRED: {
    en: "Domain is required!",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_DOMAIN_VALIDATION: {
    en: "Cannot use 'cdn', 'cms', or 'pma' as the recommended domain",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_STEP_ADDITIONAL_INFO: {
    en: "On successfully submit you will be redirected to payment page.Please keep your cards nearby to complete the payment asap.",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_PREVIOUS_BUTTON: {
    en: "Previous",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_NEXT_BUTTON: {
    en: "Next",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_PERSONAL_INFO_STEP_TITLE: {
    en: "Personal Information",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_INFO_STEP_TITLE: {
    en: "Company Information",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_ADDITIONAL_INFO_STEP_TITLE: {
    en: "Additional Information",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_PERSONAL_INFO_STEP_SUB_TITLE: {
    en: "Enter your personal detaills here.",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_INFO_STEP_SUB_TITLE: {
    en: "Enter your company detaills/UUID here.",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_ADDITIONAL_INFO_STEP_SUB_TITLE: {
    en: "Enter your recommended domain here.",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_IS_EXISTING_COMPANY: {
    en: "Are you a existing company?",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_ADD_ADDITIONAL_FIELDS: {
    en: "Add additional fields",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_REMOVE_ADDITIONAL_FIELDS: {
    en: "Remove additional fields",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_EMAIL_LABEL: {
    en: "Email",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_WEBSITE_URL_LABEL: {
    en: "Website URL",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_TELEPHONE_LABEL: {
    en: "Telephone",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_DOMAIN_VERIFICATION_ERROR: {
    en: "Please select a valid domain.",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_DOMAIN_LABEL: {
    en: "Domain",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_NAME_LABEL: {
    en: "Company Name",
    de: "",
  },
  LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_LABEL: {
    en: "Company UUID",
    de: "",
  },
  USERS_SALUATION_OPTIONS: {
    en: JSON.stringify([
      {id: 1, name: 'Mr'},
      {id: 2, name: 'Mrs'},
    ]),
    de: JSON.stringify([
      {id: 1, name: 'Herr'},
      {id: 2, name: 'Frau'},
    ]),
  },
  USERS_TITLES_OPTIONS: {
    en: JSON.stringify([
      {id: 1, name: 'Dr.'},
      {id: 2, name: 'Prof.'},
      {id: 3, name: 'Prof. Dr.'},
    ]),
    de: JSON.stringify([
      {id: 1, name: 'Dr.'},
      {id: 2, name: 'Prof.'},
      {id: 3, name: 'Prof. Dr.'},
    ]),
  },
  USERS_SALUATION_TITLE: {
    en: 'Salutation',
    de: 'Anrede',
  },
  USERS_TITLE_TITLE: {
    en: 'Title',
    de: 'Titel',
  },
  USERS_TITLES_NAME: {
    en: 'Title',
    de: 'Titel',
  },
  USERS_FIRSNAME_FIELD: {
    en: 'First name',
    de: 'Vorname',
  },
  USERS_LASTNAME_FIELD: {
    en: 'Last name',
    de: 'Nachname',
  },
  USERS_EMAIL_FIELD: {
    en: 'Email',
    de: 'E-Mail',
  },
  USERS_PASSWORD_FIELD: {
    en: 'Password',
    de: 'Passwort',
  },
  USERS_PASSWORD_INFO: {
    en: 'Use 8 or more characters with a mix of letters, numbers & symbols.',
    de: 'Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.',
  },
  USERS_CONFIRM_PASSWORD_FIELD: {
    en: 'Confirm Password',
    de: 'Passwort bestätigen',
  },
  USERS_LOADING_MESSAGE: {
    en: 'Please wait...',
    de: 'Bitte warten...',
  },
  USERS_FIRSTNAME_REQUIRED: {
    en: 'First name is required!',
    de: 'Das Feld Vornam eist ein Pflichtfeld!',
  },
  USERS_LASTNAME_REQUIRED: {
    en: 'Last name is required!',
    de: 'Das Feld Nachname ist ein Pflichtfeld!',
  },
  USERS_WRONG_EMAIL_FORMAT: {
    en: 'Wrong email format!',
    de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein!',
  },
  USERS_EMAIL_REQUIRED: {
    en: 'Email is required!',
    de: 'Das Feld E-Mail ist ein Pflichtfeld!',
  },
  USERS_USERNAME_FIELD_ERROR: {
    en: 'Invalid username',
    de: '',
  },
  USERS_PASSWORD_REQUIRED: {
    en: 'Password is required',
    de: 'Das Feld Passwort ist ein Pflichtfeld',
  },
  USERS_PASSWORD_MATCHES_INFO: {
    en: 'Password must be atleast 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!',
    de: 'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen enthalten!',
  },
  USERS_CONFIRM_PASSWORD_REQUIRED: {
    en: 'Password confirmation is required!',
    de: 'Bitte wiederholen Sie das Passwort!',
  },
  USERS_PASSWORD_MISMATCH: {
    en: "Password and Confirm Password didn't match",
    de: 'Die Passwörter stimmen nicht überein!',
  },

  ERROR_PAGE_NOT_FOUND: {
    en: 'Page Not Found',
    de: '',
  },
  ERROR_PAGE_NOT_FOUND_MESSAGE: {
    en: 'The page you looked not found!',
    de: '',
  },
  ERROR_PAGE_GO_TO_HOMEPAGE: {
    en: 'Go to homepage',
    de: '',
  },

  TASKS_TITLE: {
    en: 'Tasks',
    de: '',
  },
};
