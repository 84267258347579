import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import {
  bookSoftwareLicenseSubscription,
  getCountriesList,
  verifyDomain,
  verifyPartnerUUID,
} from "../redux/LicensingAPI";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import clsx from "clsx";
import { CountryType } from "../types/getLicensesListResponseType";
import { errorToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  closeModal: () => void;
  subscriptionId: number;
}

const initialValues = {
  salutation: 1,
  title: 0,
  firstname: "",
  lastname: "",
  email: "",
  company_uuid: "",
  company_name: "",
  company_address_1: "",
  company_address_2: "",
  company_city: "",
  company_zip_code: null,
  company_countries_id: null,
  recommended_domain: "",
  company_email: "",
  company_website_url: "",
  company_telephone: "",
};

const BookSoftwareLicenseModal: FC<Props> = ({
  show,
  closeModal,
  subscriptionId,
}) => {
  const intl = useIntl();
  const [step, setStep] = useState<number>(1);
  const [countriesList, setCountriesList] = useState<CountryType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(
    false
  );
  const [uuidVerified, setuuidVerified] = useState<boolean>(false);
  const [uuidVerifyLoading, setuuidVerifyLoading] = useState<boolean>(false);
  const [domainVerified, setDomainVerified] = useState<boolean>(false);
  const [domainVerifyLoading, setDomainVerifyLoading] = useState<boolean>(
    false
  );

  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  ) as any[];
  const TITLES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_TITLES_OPTIONS" })
  ) as any[];
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getCountriesList().then(({ data: { countries } }) => {
      setCountriesList(countries);
    });
  }, []);

  const modalSchema = Yup.object().shape(
    {
      salutation: Yup.string().required(
        intl.formatMessage({ id: "USERS_SALUATION_REQUIRED" })
      ),
      firstname: Yup.string()
        .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
        .required(intl.formatMessage({ id: "USERS_FIRSTNAME_REQUIRED" })),
      lastname: Yup.string()
        .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
        .required(intl.formatMessage({ id: "USERS_LASTNAME_REQUIRED" })),
      email: Yup.string()
        .email(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" }))
        .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
        .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
      company_uuid: Yup.string().when("company_name", {
        is: (val: string) => !val,
        then: Yup.string().required(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_OR_NAME_REQUIRED",
          })
        ),
      }),
      company_name: Yup.string().when("company_uuid", {
        is: (val: string) => !val,
        then: Yup.string().required(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_OR_NAME_REQUIRED",
          })
        ),
      }),
      company_address_1: Yup.string().when("company_name", {
        is: (val: string) => val,
        then: Yup.string().required(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_1_REQUIRED",
          })
        ),
      }),
      company_city: Yup.string().when("company_name", {
        is: (val: string) => val,
        then: Yup.string().required(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_CITY_REQUIRED",
          })
        ),
      }),
      company_zip_code: Yup.number()
        .nullable()
        .when("company_name", {
          is: (val: string) => val,
          then: Yup.number()
            .nullable()
            .required(
              intl.formatMessage({
                id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_ZIP_CODE_REQUIRED",
              })
            ),
        }),
      company_countries_id: Yup.number()
        .nullable()
        .when("company_name", {
          is: (val: string) => val,
          then: Yup.number()
            .nullable()
            .required(
              intl.formatMessage({
                id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_COUNTRY_REQUIRED",
              })
            ),
        }),
      recommended_domain: Yup.string()
        .required(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_DOMAIN_REQUIRED",
          })
        )
        .notOneOf(
          ["cdn", "cms", "pma"],
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_DOMAIN_VALIDATION",
          })
        ),
    },
    [
      ["company_uuid", "company_name"],
      ["company_name", "company_address_1"],
      ["company_name", "company_city"],
      ["company_name", "company_zip_code"],
      ["company_name", "company_countries_id"],
    ]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: modalSchema,
    enableReinitialize:true,
    onSubmit: (values, { setStatus }) => {
      setLoading(true);
      bookSoftwareLicenseSubscription(
        subscriptionId,
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.email,
        values.company_uuid,
        values.company_name,
        values.company_address_1,
        values.company_address_2,
        values.company_city,
        values.company_zip_code,
        values.company_countries_id,
        values.recommended_domain,
        values.company_email,
        values.company_website_url,
        values.company_telephone
      )
        //@ts-ignore
        .then(({ data: { stripeProductDetails } }) => {
          closeModal?.();
          //@ts-ignore
          window.location.href = stripeProductDetails?.url;
        })
        .catch((err) => {
          err.response?.data?.errors?.email
            ? setStatus(err.response.data?.errors?.email)
            : setStatus(
                intl.formatMessage({
                  id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_ADD_DETAILS_FAILURE",
                })
              );
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (!isChecked) {
      formik.setFieldValue("company_uuid", "");
    } else {
      formik.setFieldValue("company_name", "");
      formik.setFieldValue("company_address_1", "");
      formik.setFieldValue("company_address_2", "");
      formik.setFieldValue("company_city", "");
      formik.setFieldValue("company_zip_code", null);
      formik.setFieldValue("company_countries_id", null);
    }
  }, [isChecked]);

  const handleValidateStepOne = () => {
    formik.touched.firstname = true;
    formik.validateField("firstname");
    formik.touched.lastname = true;
    formik.validateField("lastname");
    formik.touched.email = true;
    formik.validateField("email");
  };

  const handleValidateStepTwo = () => {
    if (isChecked) {
      formik.touched.company_uuid = true;
      formik.validateField("company_uuid");
    } else {
      formik.touched.company_name = true;
      formik.validateField("company_name");
      formik.touched.company_address_1 = true;
      formik.validateField("company_address_1");
      formik.touched.company_city = true;
      formik.validateField("company_city");
      formik.touched.company_zip_code = true;
      formik.validateField("company_zip_code");
      formik.touched.company_countries_id = true;
      formik.validateField("company_countries_id");
    }
  };

  const handleVerifyPartnerUUID = (code: string) => {
    setuuidVerifyLoading(true);
    verifyPartnerUUID(code)
      .then(() => {
        setuuidVerifyLoading(false);
        setuuidVerified(true);
      })
      .catch(() => {
        setuuidVerifyLoading(false);
        errorToast(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_VERIFY_ERROR_MSG",
          })
        );
      });
  };

  const handleVerifyDomain = (code: string) => {
    setDomainVerifyLoading(true);
    verifyDomain(code)
      .then(() => {
        setDomainVerifyLoading(false);
        setDomainVerified(true);
      })
      .catch(() => {
        setDomainVerifyLoading(false);
        errorToast(
          intl.formatMessage({
            id: "LICENCING_BUY_SUBSCRIPTION_DOMAIN_VERIFICATION_ERROR",
          })
        );
      });
  };

  useEffect(() => {
    setDomainVerified(false);
  }, [formik.values.recommended_domain]);

  useEffect(() => {
    setuuidVerified(false);
  }, [formik.values.company_uuid]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="modal-dialog modal-dialog-centered mw-1000px"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "LEGALTECH_SOFTWARE_SUBSCRIPTIONS_BUY_MODAL_TITLE",
          })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Modal.Body style={{ minHeight: "420px", maxHeight: "420px" }}>
          <div className="d-flex justify-content-start position-relative w-100">
            <div
              className="stepper stepper-pills stepper-column d-flex flex-column position-fixed"
              id="kt_modal_create_app_stepper"
            >
              {/* begin::Aside*/}
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-xl-300px">
                {/* begin::Nav*/}
                <div className="stepper-nav ps-lg-10 d-flex flex-column gap-15 h-100 mt-5">
                  {/* begin::Step 1*/}
                  <div
                    className={`stepper-item ${step === 1 && "current"}`}
                    data-kt-stepper-element="nav"
                    style={{ pointerEvents: "none" }}
                  >
                    {/* begin::Wrapper*/}
                    <div className="stepper-wrapper d-flex cursor-pointer">
                      {/* begin::Icon*/}
                      <div
                        className="stepper-icon w-40px h-40px"
                        style={{ minWidth: "40px" }}
                      >
                        {step === 2 || step === 3 ? (
                          <i className="text-primary fas fa-check"></i>
                        ) : (
                          <span className="stepper-number">1</span>
                        )}
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_PERSONAL_INFO_STEP_TITLE",
                          })}
                        </h3>

                        <div className="stepper-desc">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_PERSONAL_INFO_STEP_SUB_TITLE",
                          })}
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className="stepper-line h-100px"></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 1*/}

                  {/* begin::Step 2*/}
                  <div
                    className={`stepper-item ${step === 2 && "current"}`}
                    data-kt-stepper-element="nav"
                    style={{ pointerEvents: "none" }}
                  >
                    {/* begin::Wrapper*/}
                    <div className="stepper-wrapper d-flex cursor-pointer">
                      {/* begin::Icon*/}
                      <div
                        className="stepper-icon w-40px h-40px"
                        style={{ minWidth: "40px" }}
                      >
                        {step === 3 ? (
                          <i className="text-primary fas fa-check"></i>
                        ) : (
                          <span className="stepper-number">2</span>
                        )}
                      </div>
                      {/* begin::Icon*/}

                      {/* begin::Label*/}
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_COMPANY_INFO_STEP_TITLE",
                          })}
                        </h3>

                        <div className="stepper-desc">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_COMPANY_INFO_STEP_SUB_TITLE",
                          })}
                        </div>
                      </div>
                      {/* begin::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className="stepper-line h-150px"></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 2*/}

                  {/* begin::Step 3*/}
                  <div
                    className={`stepper-item ${step === 3 && "current"}`}
                    data-kt-stepper-element="nav"
                    style={{ pointerEvents: "none" }}
                  >
                    {/* begin::Wrapper*/}
                    <div className="stepper-wrapper d-flex cursor-pointer">
                      {/* begin::Icon*/}
                      <div
                        className="stepper-icon w-40px h-40px text-primary"
                        style={{ minWidth: "40px" }}
                      >
                        {domainVerified ? (
                          <i className="text-primary fas fa-check"></i>
                        ) : (
                          <span className="stepper-number d-block text-primary">
                            3
                          </span>
                        )}
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_ADDITIONAL_INFO_STEP_TITLE",
                          })}
                        </h3>

                        <div className="stepper-desc">
                          {intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_ADDITIONAL_INFO_STEP_SUB_TITLE",
                          })}
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className="stepper-line h-40px"></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 3*/}
                </div>
                {/* end::Nav*/}
              </div>
              {/* begin::Aside*/}

              {/*begin::Content */}
            </div>

            <div
              className="w-100 d-flex justify-content-end "
              style={{ minHeight: "420px", maxHeight: "420px" }}
            >
              <div className="w-600px justify-self-end">
                <div className="px-4 py-2">
                  {formik.status && (
                    <div className="mb-10 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {formik.status}
                      </div>
                    </div>
                  )}
                </div>
                {step === 1 && (
                  <>
                    <div className="mb-8">
                      <label className="required fw-bold fs-6 mb-2 mb-2">
                        {intl.formatMessage({ id: "USERS_SALUATION_TITLE" })}
                      </label>
                      <DropDown
                        hideSearch
                        id="users-salutaions-dropdown"
                        items={SALUATION_OPTIONS}
                        displayFunc={(item) => {
                          return item.name;
                        }}
                        displayValue={
                          SALUATION_OPTIONS.find(
                            (SALUATION_OPTION) =>
                              SALUATION_OPTION.id === formik.values.salutation
                          )?.name || ""
                        }
                        onSelectItem={(item) => {
                          //@ts-ignore
                          formik.setFieldValue("salutation", item.id);
                        }}
                        selectedItem={SALUATION_OPTIONS.find(
                          (SALUATION_OPTION) =>
                            SALUATION_OPTION.id === formik.values.salutation
                        )}
                      />
                    </div>

                    <div className="mb-8">
                      <label className="fw-bold fs-6 mb-2">
                        {intl.formatMessage({ id: "USERS_TITLES_NAME" })}
                      </label>
                      <DropDown
                        hideSearch
                        id="users-titles-dropdown"
                        items={TITLES_OPTIONS}
                        displayFunc={(item) => {
                          return item.name;
                        }}
                        displayValue={
                          TITLES_OPTIONS.find(
                            (TITLES_OPTION) =>
                              TITLES_OPTION.id === formik.values.title
                          )?.name || ""
                        }
                        onSelectItem={(item) => {
                          //@ts-ignore
                          formik.setFieldValue("title", item.id);
                        }}
                        selectedItem={TITLES_OPTIONS.find(
                          (TITLES_OPTION) =>
                            TITLES_OPTION.id === formik.values.title
                        )}
                      />
                    </div>
                    <div className="row fv-row">
                      <div className="col-lg-6 mb-8">
                        <label className="required fw-bold fs-6 mb-2">
                          {intl.formatMessage({ id: "USERS_FIRSNAME_FIELD" })}
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          {...formik.getFieldProps("firstname")}
                          className={clsx(
                            "form-control form-control-lg form-control-solid"
                          )}
                        />
                        {formik.touched.firstname && formik.errors.firstname && (
                          <div className="text-danger">
                            <span role="alert">{formik.errors.firstname}</span>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-8">
                        {/* begin::Form group Lastname */}
                        <div className="fv-row">
                          <label className="required fw-bold fs-6 mb-2">
                            {intl.formatMessage({ id: "USERS_LASTNAME_FIELD" })}
                          </label>
                          <input
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps("lastname")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                          />
                          {formik.touched.lastname && formik.errors.lastname && (
                            <div className="text-danger">
                              <span role="alert">{formik.errors.lastname}</span>
                            </div>
                          )}
                        </div>
                        {/* end::Form group */}
                      </div>
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required fw-bold fs-6 mb-2">
                        {intl.formatMessage({ id: "USERS_EMAIL_FIELD" })}
                      </label>
                      <input
                        type="email"
                        autoComplete="off"
                        {...formik.getFieldProps("email")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid"
                        )}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.email}</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="d-flex align-items-center gap-2 mb-6">
                      <label className="fw-bold fs-5 text-gray-800">
                        {intl.formatMessage({
                          id: "LICENCING_BUY_SUBSCRIPTION_IS_EXISTING_COMPANY",
                        })}
                      </label>
                      <div className="ms-4 form-check form-switch form-switch-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="notifications"
                          value=""
                          checked={isChecked}
                          style={{ width: "50px", height: "25px" }}
                          onChange={(e) => {
                            setIsChecked(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    {isChecked && (
                      <div className="fv-row mb-8">
                        <label className=" required fw-bold fs-6 mb-2">
                          {intl.formatMessage({
                            id: "LICENCING_BUY_SUBSCRIPTION_COMPANY_UUID_LABEL",
                          })}
                        </label>
                        <div className="d-flex gap-4">
                          <input
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps("company_uuid")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                          />
                          {!uuidVerified ? (
                            <Button
                              id="kt_sign_in_submit"
                              className="btn btn-primary w-150px mx-0 px-1 d-flex justify-content-center align-items-center"
                              disabled={
                                uuidVerified || !formik.values.company_uuid
                              }
                              onClick={() => {
                                handleVerifyPartnerUUID(
                                  formik.values.company_uuid
                                );
                              }}
                            >
                              {!uuidVerifyLoading && (
                                <span className="indicator-label">
                                  {intl.formatMessage({
                                    id:
                                      "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFY_BUTTON",
                                  })}
                                </span>
                              )}
                              {uuidVerifyLoading && (
                                <div className="d-flex gap-2 align-content-center ">
                                  <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                  >
                                    {intl.formatMessage({
                                      id:
                                        "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFYING_BUTTON",
                                    })}
                                  </span>
                                  <span className="spinner-border spinner-border-sm align-middle ms-2 mt-1"></span>
                                </div>
                              )}
                            </Button>
                          ) : (
                            <Button
                              id="kt_sign_in_submit"
                              className="btn btn-success w-150px mx-0 px-1 d-flex justify-content-center align-items-center"
                              disabled={true}
                            >
                              <span className="indicator-label">
                                {intl.formatMessage({
                                  id:
                                    "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFIED_BUTTON",
                                })}
                              </span>
                            </Button>
                          )}
                        </div>
                        {formik.touched.company_uuid &&
                          formik.errors.company_uuid && (
                            <div className="text-danger">
                              <span role="alert">
                                {formik.errors.company_uuid}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {!isChecked && (
                      <>
                        <div className="fv-row mb-8">
                          <label className="required fw-bold fs-6 mb-2">
                            {intl.formatMessage({
                              id:
                                "LICENCING_BUY_SUBSCRIPTION_COMPANY_NAME_LABEL",
                            })}
                          </label>
                          <input
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps("company_name")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                          />
                          {formik.touched.company_name &&
                            formik.errors.company_name && (
                              <div className="text-danger">
                                <span role="alert">
                                  {formik.errors.company_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className="row">
                          <div className="fv-row mb-8 col-lg-6">
                            <label
                              className={`${
                                formik.values.company_name && "required"
                              } fw-bold fs-6 mb-2`}
                            >
                              {intl.formatMessage({
                                id:
                                  "LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_1_LABEL",
                              })}
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              {...formik.getFieldProps("company_address_1")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid"
                              )}
                            />
                            {formik.touched.company_address_1 &&
                              formik.errors.company_address_1 && (
                                <div className="text-danger">
                                  <span role="alert">
                                    {formik.errors.company_address_1}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="fv-row mb-8 col-lg-6">
                            <label className="fw-bold fs-6 mb-2">
                              {intl.formatMessage({
                                id:
                                  "LICENCING_BUY_SUBSCRIPTION_COMPANY_ADDRESS_2_LABEL",
                              })}
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              {...formik.getFieldProps("company_address_2")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="fv-row mb-8 col-lg-6">
                            <label
                              className={`${
                                formik.values.company_name && "required"
                              } fw-bold fs-6 mb-2`}
                            >
                              {intl.formatMessage({
                                id:
                                  "LICENCING_BUY_SUBSCRIPTION_COMPANY_CITY_LABEL",
                              })}
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              {...formik.getFieldProps("company_city")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid"
                              )}
                            />
                            {formik.touched.company_city &&
                              formik.errors.company_city && (
                                <div className="text-danger">
                                  <span role="alert">
                                    {formik.errors.company_city}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="fv-row mb-8 col-lg-6">
                            <label
                              className={`${
                                formik.values.company_name && "required"
                              } fw-bold fs-6 mb-2`}
                            >
                              {intl.formatMessage({
                                id:
                                  "LICENCING_BUY_SUBSCRIPTION_COMPANY_ZIP_CODE_LABEL",
                              })}
                            </label>
                            <input
                              type="number"
                              autoComplete="off"
                              {...formik.getFieldProps("company_zip_code")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid"
                              )}
                            />
                            {formik.touched.company_zip_code &&
                              formik.errors.company_zip_code && (
                                <div className="text-danger">
                                  <span role="alert">
                                    {formik.errors.company_zip_code}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="fv-row mb-8">
                          <label
                            className={`${
                              formik.values.company_name && "required"
                            } fw-bold fs-6 mb-2`}
                          >
                            {intl.formatMessage({
                              id:
                                "LICENCING_BUY_SUBSCRIPTION_COMPANY_COUNTRY_LABEL",
                            })}
                          </label>
                          <DropDown
                            id="countriesDropDown"
                            items={countriesList}
                            selectedItem={selectedCountry}
                            // @ts-ignore
                            onSelectItem={(item: CountryType) => {
                              formik.setFieldValue(
                                "company_countries_id",
                                item.id
                              );
                              setSelectedCountry(item);
                            }}
                            displayValue={selectedCountry?.name || ""}
                            // @ts-ignore
                            displayFunc={(item: CountryType) => {
                              return item.emoji + " " + item.name;
                            }}
                          />
                          {formik.touched.company_countries_id &&
                            formik.errors.company_countries_id && (
                              <div className="text-danger">
                                <span role="alert">
                                  {formik.errors.company_countries_id}
                                </span>
                              </div>
                            )}
                        </div>
                        <div>
                          <div
                            className={`d-flex form-check-label fw-bold  fs-6 ${
                              !showAdditionalFields
                                ? "text-primary"
                                : "text-danger"
                            }`}
                            role={"button"}
                            onClick={() => {
                              setShowAdditionalFields(!showAdditionalFields);
                            }}
                          >
                            {!showAdditionalFields ? (
                              <span>
                                +{" "}
                                {intl.formatMessage({
                                  id:
                                    "LICENCING_BUY_SUBSCRIPTION_ADD_ADDITIONAL_FIELDS",
                                })}
                              </span>
                            ) : (
                              <span>
                                -{" "}
                                {intl.formatMessage({
                                  id:
                                    "LICENCING_BUY_SUBSCRIPTION_REMOVE_ADDITIONAL_FIELDS",
                                })}
                              </span>
                            )}
                          </div>
                          {showAdditionalFields && (
                            <>
                              <div className="fv-row my-8">
                                <label className="fw-bold fs-6 mb-2">
                                  {intl.formatMessage({
                                    id:
                                      "LICENCING_BUY_SUBSCRIPTION_COMPANY_EMAIL_LABEL",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  {...formik.getFieldProps("company_email")}
                                  className={clsx(
                                    "form-control form-control-lg form-control-solid"
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-8">
                                <label className="fw-bold fs-6 mb-2">
                                  {intl.formatMessage({
                                    id:
                                      "LICENCING_BUY_SUBSCRIPTION_COMPANY_WEBSITE_URL_LABEL",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  {...formik.getFieldProps(
                                    "company_website_url"
                                  )}
                                  className={clsx(
                                    "form-control form-control-lg form-control-solid"
                                  )}
                                />
                              </div>
                              <div className="fv-row mb-8">
                                <label className="fw-bold fs-6 mb-2">
                                  {intl.formatMessage({
                                    id:
                                      "LICENCING_BUY_SUBSCRIPTION_COMPANY_TELEPHONE_LABEL",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  {...formik.getFieldProps("company_telephone")}
                                  className={clsx(
                                    "form-control form-control-lg form-control-solid"
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {step === 3 && (
                  <>
                    <div className="fv-row mb-8">
                      <label className="required fw-bold fs-6 mb-2">
                        {intl.formatMessage({
                          id: "LICENCING_BUY_SUBSCRIPTION_DOMAIN_LABEL",
                        })}
                      </label>
                      <div className="d-flex gap-4">
                        <div className="w-100 d-flex align-items-center">
                          <input
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps("recommended_domain")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid"
                            )}
                            style={{
                              borderRadius: "8px 0 0 8px",
                            }}
                          />
                          <div
                            className="fw-bold h-100 d-flex align-items-center ps-2"
                            style={{
                              width: "250px",
                              color: "#999",
                              backgroundColor: "#eef3f7",
                              borderRadius: "0 8px 8px 0",
                            }}
                          >
                            .rgc-manager.app
                          </div>
                        </div>
                        {!domainVerified ? (
                          <Button
                            id="kt_sign_in_submit"
                            className="btn btn-primary w-150px mx-0 px-1 d-flex justify-content-center align-items-center"
                            disabled={
                              domainVerified ||
                              !formik.values.recommended_domain ||
                              !!formik.errors.recommended_domain
                            }
                            onClick={() => {
                              handleVerifyDomain(
                                formik.values.recommended_domain
                              );
                            }}
                          >
                            {!domainVerifyLoading && (
                              <span className="indicator-label">
                                {intl.formatMessage({
                                  id:
                                    "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFY_BUTTON",
                                })}
                              </span>
                            )}
                            {domainVerifyLoading && (
                              <div className="d-flex gap-2 align-content-center ">
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  {intl.formatMessage({
                                    id:
                                      "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFYING_BUTTON",
                                  })}
                                </span>
                                <span className="spinner-border spinner-border-sm align-middle ms-2 mt-1"></span>
                              </div>
                            )}
                          </Button>
                        ) : (
                          <Button
                            id="kt_sign_in_submit"
                            className="btn btn-success w-150px mx-0 px-1 d-flex justify-content-center align-items-center"
                            disabled={true}
                          >
                            <span className="indicator-label">
                              {intl.formatMessage({
                                id:
                                  "LICENCING_BUY_SUBSCRIPTION_FLOW_VERIFIED_BUTTON",
                              })}
                            </span>
                          </Button>
                        )}
                      </div>
                      {formik.touched.recommended_domain &&
                        formik.errors.recommended_domain && (
                          <div className="text-danger">
                            <span role="alert">
                              {formik.errors.recommended_domain}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className="p-5 mx-4 mb-4 d-flex align-items-start rounded border border-rounded flex-even gap-2 border-secondary">
                      <i className="bi bi-info-circle-fill align-self-start mt-1 text-primary "></i>
                      <span
                        className="fs-6 fw-bolder"
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id:
                              "LICENCING_BUY_SUBSCRIPTION_STEP_ADDITIONAL_INFO",
                          }),
                        }}
                      ></span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              if (step === 3) {
                setStep(2);
              } else if (step === 2) {
                setStep(1);
              } else {
                closeModal();
              }
            }}
          >
            {step === 1
              ? intl.formatMessage({ id: "CLOSE_BUTTON" })
              : intl.formatMessage({
                  id: "LICENCING_BUY_SUBSCRIPTION_PREVIOUS_BUTTON",
                })}
          </Button>
          <Button
            id="kt_sign_in_submit"
            className="btn  btn-primary"
            disabled={
              (step === 2 && isChecked && !uuidVerified) ||
              (step === 3 && !domainVerified)
            }
            onClick={() => {
              if (step === 1) {
                handleValidateStepOne();
                if (
                  !formik.errors.email &&
                  !formik.errors.firstname &&
                  !formik.errors.lastname
                ) {
                  setStep(2);
                }
              } else if (step === 2) {
                handleValidateStepTwo();
                if (isChecked && !formik.errors.company_uuid) {
                  setStep(3);
                } else if (
                  !isChecked &&
                  !formik.errors.company_name &&
                  !formik.errors.company_address_1 &&
                  !formik.errors.company_city &&
                  !formik.errors.company_zip_code &&
                  !formik.errors.company_countries_id
                ) {
                  setStep(3);
                }
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {!loading && (
              <span className="indicator-label">
                {step === 3
                  ? intl.formatMessage({ id: "SUBMIT_BUTTON" })
                  : intl.formatMessage({
                      id: "LICENCING_BUY_SUBSCRIPTION_NEXT_BUTTON",
                    })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BookSoftwareLicenseModal;
