import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'

interface Props {
  show: boolean
  closeModal: () => void
  verifySubscriptionAPI: (partnerUUID: string, verificatonCode: string) => void
  verifyLoading: boolean
}

const initialValues = {
  partner_uuid: '',
  verification_code: '',
}

const VerifySubscriptionModal: FC<Props> = ({
  show,
  closeModal,
  verifySubscriptionAPI,
  verifyLoading,
}) => {
  const intl = useIntl()

  const modalSchema = Yup.object().shape({
    partner_uuid: Yup.string().required(
      intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_PARTNER_UUID_REQUIRED'})
    ),
    verification_code: Yup.string().required(
      intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_VERIFICATION_CODE_REQUIRED'})
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: modalSchema,
    onSubmit: (values) => {
      verifySubscriptionAPI(values.partner_uuid, values.verification_code)
    },
  })
  return (
    <Modal
      show={show}
      centered
      dialogClassName='medium-size-modal'
      contentClassName={verifyLoading ? 'pe-none' : ''}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_TITLE'})}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        <Modal.Body>
          <div className='px-3 py-0'>
            <div className='fv-row mb-8'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_PARTNER_UUID'})}
              </label>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('partner_uuid')}
                className={clsx('form-control form-control-lg form-control-solid')}
              />
              {formik.touched.partner_uuid && formik.errors.partner_uuid && (
                <div className='text-danger'>
                  <span role='alert'>{formik.errors.partner_uuid}</span>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_MODAL_VERIFICATION_CODE'})}
              </label>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('verification_code')}
                className={clsx('form-control form-control-lg form-control-solid')}
              />
              {formik.touched.verification_code && formik.errors.verification_code && (
                <div className='text-danger'>
                  <span role='alert'>{formik.errors.verification_code}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant='secondary' onClick={closeModal}>
            {intl.formatMessage({id: 'CLOSE_BUTTON'})}
          </Button>
          <Button type='submit' id='kt_sign_in_submit' className='btn  btn-primary'>
            {!verifyLoading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT_BUTTON'})}</span>
            )}
            {verifyLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'USERS_LOADING_MESSAGE'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default VerifySubscriptionModal
