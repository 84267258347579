import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import languages from './messages/language'

// const allMessages = {
//   // de: deMessages,
//   // en: enMessages,
//   // es: esMessages,
//   // fr: frMessages,
//   // ja: jaMessages,
//   // zh: zhMessages,
// }

type Props = {
  children: React.ReactNode
}

const I18nProvider: FC<Props> = ({children}) => {
  const locale = useLang()
  // const messages = allMessages[locale]
  const tempObj = {}

  Object.keys(languages).map((key) => {
    //@ts-ignore
    tempObj[key] = languages[key][locale]
  })
  // console.log(tempObj)
  // })

  return (
    <IntlProvider locale={locale} messages={tempObj}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
