import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import LicenseCard from './LicenseCard'
import {LicenseSubscriptionType} from '../types/getLicensesListResponseType'
import {getLicensesList, verifyLegaltechSubscription} from '../redux/LicensingAPI'
import SimpleLoader from '../../../sharedComponents/Loader/SimpleLoader'
import VerifySubscriptionModal from './VerifySubscriptionModal'
import {SuccessAlert, errorAlert} from '../../../sharedComponents/Alert'
// import {useSelector} from 'react-redux'
// import {RootState} from '../../../../setup'
// import {SystemSettings} from '../../system/types/getSystemSettingsResponseType'
import {Link} from 'react-router-dom'

const LicenseMicroPage = () => {
  const intl = useIntl()

  const [legalTechSoftwareDetails, setLegalTechSoftwareDetails] = useState<
    LicenseSubscriptionType[]
  >([])
  const [loading, setLoading] = useState(false)
  const [verifySubscription, setVerifySubscription] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)

  // const SSettings = useSelector<RootState>(
  //   ({masterdata}) => masterdata.systemSettingsList
  // ) as SystemSettings

  const getLicensesListAPI = () => {
    setLoading(true)
    getLicensesList()
      .then(({data: {legalTechSoftwareDetails: legalTechSoftware = []}}) => {
        setLegalTechSoftwareDetails(legalTechSoftware)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // if(history?.location?.state && history?.location?.state?.from === 'paymentSuccess'){

    // }
    getLicensesListAPI()
  }, [])

  const verifySubscriptionAPI = (partnerUUID: string, verificatonCode: string) => {
    setVerifyLoading(true)
    verifyLegaltechSubscription(partnerUUID, verificatonCode)
      .then(() =>
        SuccessAlert(intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_SUCCESS_MESSAGE'}))
      )
      .catch(() => {
        errorAlert(intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_FAILURE_MESSAGE'}))
      })
      .finally(() => {
        setVerifySubscription(false)
        setVerifyLoading(false)
      })
  }

  return (
    <>
      {verifySubscription && (
        <VerifySubscriptionModal
          show={verifySubscription}
          closeModal={() => setVerifySubscription(false)}
          verifySubscriptionAPI={verifySubscriptionAPI}
          verifyLoading={verifyLoading}
        />
      )}
      {loading ? (
        <div className='m-auto'>
          <SimpleLoader />
        </div>
      ) : (
        <div className=' w-100 h-100 bg-white'>
          <div className='mx-auto' style={{maxWidth: '1000px'}}>
            {/* <div className='d-flex justify-content-center'>
              <Link to='/dashboard'>
                {SSettings && SSettings.logo_rectangle_url && (
                  <img
                    alt=''
                    width='180px'
                    className='logo mt-5'
                    src={SSettings && SSettings.logo_rectangle_url && SSettings.logo_rectangle_url}
                    style={{objectFit: 'contain', maxHeight: '150px'}}
                  />
                )}
              </Link>
            </div> */}

            <div
              className='fw-bolder text-center'
              style={{fontSize: '45px', marginBottom: '30px', marginTop: '30px'}}
            >
              <div style={{marginBottom: '-5px'}}>
                {intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_TITLE_1'})}
              </div>
              <div>
                {intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_MICROPAGE_TITLE_2'})}
              </div>

              <div className='fs-5 mt-5'>
                {intl.formatMessage({
                  id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_ALREADY_HAVE_SUBSCRIPTION',
                })}
                <span
                  className='ms-2 text-primary-2 cursor-pointer'
                  onClick={() => setVerifySubscription(true)}
                >
                  {intl.formatMessage({id: 'LEGALTECH_VERIFY_SUBSCRIPTION_VERIFY_BTN'})}
                </span>
              </div>
            </div>
            <div
              className='mt-15 d-flex gap-10 justify-content-center align-items-center mx-auto'
              style={{flexWrap: 'wrap', maxWidth: '1200px', paddingBottom: '50px'}}
            >
              {legalTechSoftwareDetails && legalTechSoftwareDetails?.length > 0 ? (
                legalTechSoftwareDetails?.map((item, index) => (
                  <LicenseCard item={item} key={index} />
                ))
              ) : (
                <div className='fw-bold fs-5 p-20'>
                  {intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS__EMPTY'})}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LicenseMicroPage
