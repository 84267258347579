import clsx from 'clsx'
import React, {FC} from 'react'

// styles
import './Loader.scss'
import { useIntl } from 'react-intl'

interface Props {
  fullLoader?: boolean
}

const SimpleLoader: FC<Props> = ({fullLoader}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={clsx(
          'd-flex flex-row align-items-center justify-content-center p-10',
          fullLoader ? 'spinner-centre' : ''
        )}
      >
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>{intl.formatMessage({id:'COMMON_LOADING'})}</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>{intl.formatMessage({id:'COMMON_LOADING'})}</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>{intl.formatMessage({id:'COMMON_LOADING'})}</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>{intl.formatMessage({id:'COMMON_LOADING'})}</span>
        </div>
      </div>
    </>
  )
}

export default SimpleLoader
