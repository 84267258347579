import {toast} from 'react-toastify'

export const successToast = (text: string) => {
  toast.success(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
  })
}

export const errorToast = (text: string) => {
  toast.error(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
  })
}
