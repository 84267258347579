import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import SimpleLoader from '../../../sharedComponents/Loader/SimpleLoader'
import {LicenseSubscriptionType} from '../types/getLicensesListResponseType'
// import {SystemSettings} from '../../system/types/getSystemSettingsResponseType'
// import {RootState} from '../../../../setup'
// import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import LicenseCard from './LicenseCard'
import {buyDataUserLegaltechSubscriptions, getLicensesList} from '../redux/LicensingAPI'
import {errorAlert} from '../../../sharedComponents/Alert'

const LicenseUserDataMicroPage = () => {
  const intl = useIntl()

  // const SSettings = useSelector<RootState>(
  //   ({masterdata}) => masterdata.systemSettingsList
  // ) as SystemSettings

  const [loading, setLoading] = useState(false)
  const [dataUserLoading, setDataUserLoading] = useState(false)
  const [legalTechDataDetails, setLegalTechDataDetails] = useState<LicenseSubscriptionType[]>([])
  const [legalTechUserDetails, setLegalTechUserDetails] = useState<LicenseSubscriptionType[]>([])
  const [selectedDataItems, setSelectedDataItems] = useState<any>([])
  const [selectedUserItems, setSelectedUserItems] = useState<any>([])

  const getLicensesListAPI = () => {
    setLoading(true)
    getLicensesList()
      .then(
        ({
          data: {
            legalTechDataDetails: legalTechData = [],
            legalTechUserDetails: legalTechUser = [],
          },
        }) => {
          setLegalTechDataDetails(legalTechData)
          setLegalTechUserDetails(legalTechUser)
        }
      )
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // if(history?.location?.state && history?.location?.state?.from === 'paymentSuccess'){

    // }
    getLicensesListAPI()
  }, [])

  const handleDataUserSubscription = (
    subscriptions_id: number[],
    partner_uuid: string,
    verification_code: string
  ) => {
    setDataUserLoading(true)
    buyDataUserLegaltechSubscriptions(subscriptions_id, partner_uuid, verification_code)
      //@ts-ignore
      .then(({data: {stripeProductDetails}}) => (window.location.href = stripeProductDetails?.url))
      .catch(() => {
        errorAlert(intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_ADD_DETAILS_FAILURE'}))
      })
      .finally(() => {
        setDataUserLoading(false)
      })
  }

  return (
    <>
      {loading ? (
        <div className='m-auto'>
          <SimpleLoader />
        </div>
      ) : (
        <div className='bg-white w-100 h-100'>
          {/* <div className='text-center'>
            <Link to='/dashboard'>
              {SSettings && SSettings.logo_rectangle_url && (
                <img
                  alt=''
                  width='180px'
                  className='logo mt-5'
                  src={SSettings && SSettings.logo_rectangle_url && SSettings.logo_rectangle_url}
                  style={{objectFit: 'contain', maxHeight: '150px'}}
                />
              )}
            </Link>
          </div> */}
          <div className='mx-auto'>
            <div
              className='fw-bolder text-center'
              style={{fontSize: '45px', marginBottom: '30px', marginTop: '30px'}}
            >
              {intl.formatMessage({id: 'LEGALTECH_DATA_SUBSCRIPTIONS_MICROPAGE_TITLE'})}
              <div className='fs-5 mt-8' style={{minHeight: '40px'}}>
                {selectedDataItems?.length > 0 ? (
                  <div>
                    <span>
                      {selectedDataItems?.length}{' '}
                      {intl.formatMessage({id: 'LEGALTECH_DATA_USER_SUBSCRIPTIONS_SELECTED'})}
                    </span>
                    <button
                      className='btn btn-primary-2 text-white fs-6 py-2 ms-2'
                      onClick={() => handleDataUserSubscription(selectedDataItems, 'bvf', 'bfgb')}
                    >
                      {!dataUserLoading && (
                        <span className='indicator-label'>
                          {intl.formatMessage({
                            id: 'LEGALTECH_DATA_USER_SUBSCRIPTIONS_MICROPAGE_BUY',
                          })}
                        </span>
                      )}

                      {dataUserLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'USERS_LOADING_MESSAGE'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                ) : (
                  <div>
                    {intl.formatMessage({id: 'LEGALTECH_DATA_USER_SELECT_SUBSCRIPTIONS_MESSAGE'})}
                  </div>
                )}
              </div>
            </div>
            <div
              className='d-flex gap-8 justify-content-center align-items-center mx-auto'
              style={{flexWrap: 'wrap', maxWidth: '900px', paddingBottom: '50px'}}
            >
              {legalTechDataDetails?.map((item, index) => (
                <LicenseCard
                  item={item}
                  key={index}
                  isNotSoftware={true}
                  selectedDataItems={selectedDataItems}
                  setSelectedDataItems={setSelectedDataItems}
                  type='data'
                />
              ))}
            </div>
            <div
              className='fw-bolder text-center'
              style={{fontSize: '45px', marginBottom: '30px', marginTop: '50px'}}
            >
              {intl.formatMessage({id: 'LEGALTECH_USER_SUBSCRIPTIONS_MICROPAGE_TITLE'})}
              <div className='fs-5 mt-8' style={{minHeight: '40px'}}>
                {selectedUserItems?.length > 0 ? (
                  <div>
                    <span>
                      {selectedUserItems?.length}{' '}
                      {intl.formatMessage({id: 'LEGALTECH_DATA_USER_SUBSCRIPTIONS_SELECTED'})}
                    </span>
                    <button
                      className='btn btn-primary-2 text-white fs-6 py-2 ms-2'
                      onClick={() => handleDataUserSubscription(selectedUserItems, 'bvf', 'bfgb')}
                    >
                      {!dataUserLoading && (
                        <span className='indicator-label'>
                          {intl.formatMessage({
                            id: 'LEGALTECH_DATA_USER_SUBSCRIPTIONS_MICROPAGE_BUY',
                          })}
                        </span>
                      )}

                      {dataUserLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'USERS_LOADING_MESSAGE'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                ) : (
                  <div>
                    {intl.formatMessage({id: 'LEGALTECH_DATA_USER_SELECT_SUBSCRIPTIONS_MESSAGE'})}
                  </div>
                )}
              </div>
            </div>
            <div
              className='d-flex gap-8 justify-content-center align-items-center mx-auto'
              style={{flexWrap: 'wrap', maxWidth: '900px', paddingBottom: '50px'}}
            >
              {legalTechUserDetails?.map((item, index) => (
                <LicenseCard
                  item={item}
                  key={index}
                  isNotSoftware={true}
                  selectedUserItems={selectedUserItems}
                  setSelectedUserItems={setSelectedUserItems}
                  type='user'
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LicenseUserDataMicroPage
