import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
// import {RootState} from '../../../../setup'
// import {useSelector} from 'react-redux'
// import {SystemSettings} from '../../system/types/getSystemSettingsResponseType'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import SuccessICon from '../../../_metronic/assets/icons/success.jpg'
import {fetchSoftwareSubscriptionStatus} from '../redux/LicensingAPI'
import {useHistory} from 'react-router'

const LegalTechSoftwarePaymentSuccess = () => {
  // const SSettings = useSelector<RootState>(
  //   (state) => state.masterdata.systemSettingsList
  // ) as SystemSettings

  const intl = useIntl()
  const history = useHistory()
  const [successButton, setSuccessButton] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false)

  useEffect(() => {
    const search = window.location.search
    const searchURLparams = new URLSearchParams(search)
    const id = searchURLparams.get('id')
    const type = searchURLparams.get('type')
    const orderNumber = searchURLparams.get('orderNumber')

    if (id && orderNumber) {
      fetchSoftwareSubscriptionStatus(type, id, 'success', orderNumber)
        .then(() => {
          setSuccessButton(true)
        })
        .catch(() => {
          setPaymentFailed(true)
        })
    }
  }, [])

  return (
    <div style={{backgroundColor: '#ffffff'}} className='d-flex h-100 flex-column'>
      {/* <div className='d-flex flex-center flex-column  p-5 pb-0  '>
        {SSettings && SSettings.logo_rectangle_url && (
          <div className='mb-12'>
            <img
              alt=''
              style={{width: '250px', height: '140.6px'}}
              src={SSettings && SSettings.logo_rectangle_url && SSettings.logo_rectangle_url}
            />
          </div>
        )}
      </div> */}

      <div
        className='p-4 d-flex  align-items-center flex-column fw-bold'
        style={{flex: 1, marginTop: '160px'}}
      >
        <div
          className='d-flex justify-content-center align-items-center flex-column fw-bold'
          style={{fontSize: '20px'}}
        >
          {!successButton ? (
            <div>
              <div
                className='d-flex justify-content-center align-items-center flex-column fw-bold'
                style={{marginBottom: '30px'}}
              >
                <span className='spinner-border spinner-border-lg align-middle ms-2 text-primary'></span>
              </div>
              {intl.formatMessage({id: 'EVENT_MICRO_PAGE_PAYMENT_LOADING_MESSAGE'})}
            </div>
          ) : (
            <div>
              <div className='d-flex justify-content-center align-items-center flex-column fw-bold'>
                <div style={{marginBottom: '30px'}}>
                  {paymentFailed
                    ? intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_FAILED'})
                    : intl.formatMessage({id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_SUCCESS'})}
                </div>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    history.push(`/legaltech/software/subscriptions`, {
                      from: paymentFailed ? 'paymentFailed' : 'paymentSuccess',
                    })
                  }}
                >
                  {intl.formatMessage({
                    id: 'LEGALTECH_SOFTWARE_SUBSCRIPTIONS_PAYMENT_SUCCESS_BACK',
                  })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer notShowCompany={true} />
    </div>
  )
}

export default LegalTechSoftwarePaymentSuccess
