import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// custom
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { Routes } from "./routing/Routes";
import GlobalModal from "./sharedComponents/modals/ModalContext";

type Props = {
  basename?: string;
};

const App: React.FC<Props> = ({ basename }) => {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* @ts-ignore */}
      <BrowserRouter>
        <I18nProvider>
          <LayoutProvider>
            <GlobalModal>
              <ToastContainer />
              <Routes />
            </GlobalModal>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
